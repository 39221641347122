import React from "react";
import { connect } from "react-redux";
import { Grid, Menu } from "semantic-ui-react";
import { NavLink, Route, Routes } from "react-router-dom";
import Edit from "./components/edit";
import Notifications from "./components/notifications";
import Exports from "./components/exports";
import Artists from "./components/artists";
import Tags from "./components/tags";
import Preferences from "./components/preferences";
import Billing from "./components/billing";
import DangerZone from "./components/danger_zone";
import AuthLayout from "../../../../layouts/AuthLayout";
import { ACCOUNT_PATH } from "../../../../constants/paths";
import InvitationRequests from "./components/billing/InvitationRequests";

const Account = ({ match, user }) => {
  return (
    <AuthLayout title={"Account"}>
      <Grid stackable>
        <Grid.Row centered>
          <Grid.Column width={3}>
            <Menu fluid pointing vertical>
              <Menu.Item
              end
                as={NavLink}
                name="edit profile"
                to={`${ACCOUNT_PATH}`}
              />

              <Menu.Item
                as={NavLink}
                name="notifications"
                to={`${ACCOUNT_PATH}/notifications`}
              />

              <Menu.Item
                as={NavLink}
                name="artists"
                to={`${ACCOUNT_PATH}/artists`}
              />

              <Menu.Item
                as={NavLink}
                name={"preferences"}
                to={`${ACCOUNT_PATH}/preferences`}
              />

              <Menu.Item
                as={NavLink}
                name={"tags"}
                to={`${ACCOUNT_PATH}/tags`}
              />

              <Menu.Item
                as={NavLink}
                name={"billing"}
                to={`${ACCOUNT_PATH}/billing`}
              />

              <Menu.Item
                as={NavLink}
                name="exports"
                to={`${ACCOUNT_PATH}/exports`}
              />

              <Menu.Item
                as={NavLink}
                name={"danger zone"}
                to={`${ACCOUNT_PATH}/danger_zone`}
              />
            </Menu>
              
          </Grid.Column>
          <Grid.Column width={13}>
            <InvitationRequests />
            <Routes>
              <Route path={`/`} element={<Edit />} />
              <Route path={`notifications`} element={<Notifications />} />
              <Route path={`exports`} element={<Exports />} />
              <Route path={`artists`} element={<Artists />} />
              <Route path={`tags`} element={<Tags />} />
              <Route path={`preferences`} element={<Preferences />} />
              <Route path={`danger_zone`} element={<DangerZone />} />
              <Route path={`billing`} element={<Billing />} />
            </Routes>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

export default connect(mapStateToProps)(Account);
