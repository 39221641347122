import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Grid, Header, Loader, Segment } from "semantic-ui-react";
import { clean } from "../../../../../../utils/utils";
import { updateCurrentUser } from "../../../../../../redux/actions/me";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import {
  currencyOptions,
  languageOptions,
  measurementOptions,
} from "../../../../../../common";
import DropZone from "../../../../../../components/DropZone";

const EditPreferences = ({ user, update }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {}, [user]);

  const onSubmit = async () => {
    setError(null);
    setLoading(true);

    const data = clean({
    });

    var formData = new FormData();
    if (file) {
      formData.append("business_logo", file);
    }

    for (var key in data) {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    try {
      await update(formData);
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header size={"huge"} content={"Preferences"} dividing />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          {error && <ErrorMessage error={error} />}
          <Segment>
            {!user && <Loader />}
            {user && user._id && (
              <Form onSubmit={onSubmit}>

                <Form.Group>
                  <Form.Field width={4}>
                    <label>Logo</label>
                  </Form.Field>
                  <Form.Field width={12}>
                    <DropZone
                      onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
                      multiple={false}
                      accept="image/*"
                      icon={"image"}
                      image={(file && file.preview) || user.business_logo}
                      placeholder={"Drag artist image here or click to upload"}
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Button
                  primary
                  type={"submit"}
                  loading={loading}
                  content={"Save"}
                />
              </Form>
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile,
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateCurrentUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPreferences);
